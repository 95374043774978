.page-container {
  position: relative;
  overflow-x: clip;
}

[data-about-text] {
  @media #{$breakpoint-md-up} {
    pointer-events: none;
  }

  &.about__content--ready {
    @media #{$breakpoint-md-up} {
      pointer-events: all;
    }
  }

  span {
    position: relative;
    will-change: transform;
    transform-origin: center;
    display: inline-block;
    opacity: 0.03;
    transform-origin: center;
    transition: transform 1s ease-out, opacity 1s ease-in;

    @media #{$breakpoint-md-up} {
      transition: transform 1s ease-in, opacity 1s ease-in;
    }
  }

  a:hover {
    @media #{$breakpoint-md-up} {
      color: $color-light-grey;
      text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
    }
  }
}

.about {
  position: relative;
  z-index: 2;
  margin-top: calc(#{$row-height--mobile}*1.5);
  margin-bottom: calc(#{$row-height--mobile}*5);

  @media #{$breakpoint-sm-up} {
    margin-top: calc(#{$row-height}*6);
    margin-bottom: calc(#{$row-height}*6);
  }

  @media #{$breakpoint-md-up} {
    margin-top: calc(#{$row-height}*5);
    margin-bottom: calc(#{$row-height}*5);
  }

  p {
    line-height: 1.3;

    @media #{$breakpoint-sm-up} {
      font-size: 2.6vw;
    }

    @media #{$breakpoint-md-up} {
      font-size: clamp(16px, 3.1vw, 50px);
      letter-spacing: -0.025em;
    }

    & + & {
      padding-top: 1em;
    }
  }

  p + p {
    margin-top: 1em;
  }

  &__content {
    position: relative;
  }
}

.projects-container {
  position: relative;
}


.projects {
  position: relative;

  &__hovered-proj {
    width: 1.5px;
    height: $row-height--mobile;
    background-color: black;
    position: fixed;
    top: calc(#{$row-height--mobile} * -1);
    margin-left: calc((#{$gutter-size--mobile} + 1.5px)*-1);
    opacity: 0;
    transition: opacity 0.15s ease;
  }
  &:hover &__hovered-proj {
    height: $row-height;
    top: calc(#{$row-height} * -1);
    margin-left: calc((#{$gutter-size} + 1.5px)*-1);
    opacity: 1;
  }
}


.project-bg {
  position: fixed;
  inset: 0;
  z-index: -1;
  filter: blur(40px) saturate(4);
  pointer-events: none;

  video, img {
    width: 110%;
    height: 110%;
    inset: -5%;
    position: absolute;
    object-fit: cover;
    object-position: top left;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0s ease 0.5s;
  }

  &:before {
    content: '';
    position: absolute;
    inset: -40px;
    bottom: initial;
    height: 30%;
    z-index: 2;
    // background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
      --mask:
        radial-gradient(107.35px at 50% calc(100% - 145px),#fbfbfb 99%,#fbfbfb00 101%) calc(50% - 100px) 0/200px 100%,
        radial-gradient(107.35px at 50% calc(100% + 95px),#fbfbfb00 99%,#fbfbfb 101%) 50% calc(100% - 50px)/200px 100% repeat-x;
      background: var(--mask);
  }

  & &__video--active {
    visibility: visible;
    opacity: 0.2;
    transition: opacity 0.3s ease, visibility 0s ease 0s;
  }
}


.project {
  will-change: height;
  z-index: 1;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: max-height 1.25s cubic-bezier(0.25, 0.1, 0.25, 1);
  max-height: calc($row-height--mobile*2 + 1.4em);
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards 1s;

  @media #{$breakpoint-sm-up} {
    max-height: calc($row-height--mobile*2);
  }

  @media #{$breakpoint-md-up} {
    transition: max-height 0.75s cubic-bezier(0.25, 0.1, 0.25, 1);
    max-height: $row-height;
  }

  &:not(:last-child) {
    margin-bottom: $gutter-size--mobile;

    @media #{$breakpoint-md-up} {
      margin-bottom: 0;
    }
  }

  * {
    @media #{$breakpoint-md-up} {
      line-height: 1;
    }
  }

  a {
    pointer-events: none;
  }

  &:nth-child(2) {
    margin-top: calc(($row-height--mobile + $gutter-size--mobile) * -1);
    @media #{$breakpoint-md-up} {
      margin-top: calc($row-height * -1);
    }
  }

  &--active {
    max-height: 200vw !important;

    @media #{$breakpoint-md-up} {
      max-height: calc(100vw / #{$grid-columns} * 10) !important;
    }

    a {
      pointer-events: all;
      text-decoration: underline;
      text-decoration-thickness: 0.5px;
      text-underline-offset: 2px;
      text-decoration-color: rgba(0, 0, 0, 0.5);
    }

    &:after {
      right: 0%;
      opacity: 1;
    }
  }

  &.project--heading {
    top: calc($row-height--mobile + $gutter-size--mobile);
    height: calc($row-height--mobile + $gutter-size--mobile);
    pointer-events: none;
    position: sticky;
    z-index: 3;
    margin-bottom: 0;


    @media #{$breakpoint-xs-only} {
      top: calc($row-height--mobile + $gutter-size--mobile) !important;
    }
    @media #{$breakpoint-sm-only} {
      top: calc($row-height--mobile + $gutter-size--mobile) !important;
    }

    @media #{$breakpoint-md-up} {
      top: $row-height;
      height: $row-height;
    }

    a {
      pointer-events: all;
    }
  }

  &.project--year {
    position: sticky;
    height: calc($row-height--mobile + $gutter-size--mobile);
    top: calc($row-height--mobile + $gutter-size--mobile);
    margin-bottom: 0;

    @media #{$breakpoint-md-up} {
      height: $row-height;
      top: $row-height;
    }
      
    .projects__group:nth-child(2) & {
      margin-top: calc(($row-height--mobile + $gutter-size--mobile) * -1);
      @media #{$breakpoint-md-up} {
        margin-top: calc($row-height * -1);
      }
    }
  }

  &__content--title {
    .project--active & a:first-child:after {
      content: '';
      background-image: url(../img/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 0.75em;
      height: 0.75em;
      display: inline-block;
      margin-left: 0.4em;
      margin-bottom: -0.1em;
    }

    & > * {
      @media #{$breakpoint-md-up} {
        width: 82%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    p + p {
      margin-top: calc($row-height--mobile / 2);
      @media #{$breakpoint-md-up} {
        margin-top: $row-height;
      }
    }

    &--align-right {
      text-align: right;
    }
  }

  &__column {
    padding-top: $gutter-size--mobile;
    padding-bottom: $gutter-size--mobile;
    position: relative;

    @media #{$breakpoint-md-up} {
      padding-top: $gutter-size;
      padding-bottom: $gutter-size;

      &--1:before {
        content: '';
        width: 1.5px;
        height: 0%;
        background-color: black;
        position: absolute;
        top: 0px;
        left: -1.5px;
        transition: height 0.75s ease;

        .project--active & {
          height: 100%;
        }
      }
    }
  }

  &__column-img  {
    position: relative;
    padding: 0;
    overflow-y: hidden;
    max-height: 200vw;
    display: none;

    @media #{$breakpoint-md-up} {
      max-height: calc(100vw / #{$grid-columns} * 10);
    }

    .in-viewport & {
      display: block;
    }

    .project__content {
      height: 100%;
      display: block;
      position: relative;
      filter: blur(15px) saturate(1.05);
      transition: filter 0.25s ease-in;

      .project--active.project--video-loaded & {
        filter: blur(0px) saturate(1) !important;
      }
    }

    img {
      z-index: 2;
      max-inline-size: 100%;
      background-color: lightgray;
      position: absolute;
      inset: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      pointer-events: none;
      opacity: 1;
      transition: opacity 0s ease 0.75s;

      .project--active.project--video-loaded & {
        opacity: 0;
        transition: opacity 0s ease 0s;
      }
    }

    video {
      position: relative;
      max-inline-size: 100%;
      height: auto;
      visibility: hidden;
      transition: visibility 0s ease 1s;
    }
    .project--active & video {
      visibility: visible;
      transition: visibility 0s ease 0s;
    }
  }

  &__description {
    max-width: 95%;

    @media #{$breakpoint-md-up} {
      line-height: 1.3;
    }

    &:after {
      content: '＊';
      position: relative;
      display: block;
      margin-top: calc(1.4em* 1.5);
      margin-bottom: calc(1.4em* 3);
      text-align: center;

      @media #{$breakpoint-md-up} {
        display: none;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}