/* GRID */
$grid-columns: 24; // Set number of columns in the grid

$xs-max: 640px; // 40rem
$sm-max: 1023px; // 64rem
$md-max: 1399px; // 90rem
$lg-max: 1790px; // $lg-max: 1920px; // 120rem

/* GUTTER AND CONTAINER PAD */
/* gutter and container pad changes on sm */
$gutter-size: 7.5px;
$gutter: 0 $gutter-size; // Set gutter size

$gutter-size--mobile: 7.5px;
$gutter--mobile: 0 $gutter-size--mobile; // Set gutter size

$container-pad: 10px;


$row-height--mobile: calc(1rem + #{$gutter-size--mobile}*2);
$row-height: calc(1rem + #{$gutter-size}*2);


$color-light-grey: #f8f8f8;
