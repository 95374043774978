.column-test {
  background-color: rgba(255,0,0,0.1);
  min-height: 100vh;
}

.col-md-offset-0 {
  @media #{$breakpoint-md-up} {
    margin-left: 0;
  }
}

.text-right {
  text-align: right;
}