*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace, monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace, monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: Sligoil Micro;
  src: url("sligoil-micro-w.ee08f190.woff2") format("woff2"), url("sligoil-micro-w.415f6628.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.row {
  box-sizing: border-box;
  width: 100%;
  flex-flow: wrap;
  flex: 0 auto;
  margin: 0 auto;
  display: flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.natural-height {
  align-items: flex-start;
}

.row.flex-column {
  flex-direction: column;
}

.col {
  box-sizing: border-box;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 7.5px;
}

.col.reverse {
  flex-direction: column-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.col-xs {
  box-sizing: border-box;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 7.5px;
}

.col-xs-1 {
  box-sizing: border-box;
  max-width: 4.16667%;
  flex-basis: 4.16667%;
  padding: 0 7.5px;
}

.col-xs-offset-1 {
  margin-left: 4.16667%;
}

.col-xs-2 {
  box-sizing: border-box;
  max-width: 8.33333%;
  flex-basis: 8.33333%;
  padding: 0 7.5px;
}

.col-xs-offset-2 {
  margin-left: 8.33333%;
}

.col-xs-3 {
  box-sizing: border-box;
  max-width: 12.5%;
  flex-basis: 12.5%;
  padding: 0 7.5px;
}

.col-xs-offset-3 {
  margin-left: 12.5%;
}

.col-xs-4 {
  box-sizing: border-box;
  max-width: 16.6667%;
  flex-basis: 16.6667%;
  padding: 0 7.5px;
}

.col-xs-offset-4 {
  margin-left: 16.6667%;
}

.col-xs-5 {
  box-sizing: border-box;
  max-width: 20.8333%;
  flex-basis: 20.8333%;
  padding: 0 7.5px;
}

.col-xs-offset-5 {
  margin-left: 20.8333%;
}

.col-xs-6 {
  box-sizing: border-box;
  max-width: 25%;
  flex-basis: 25%;
  padding: 0 7.5px;
}

.col-xs-offset-6 {
  margin-left: 25%;
}

.col-xs-7 {
  box-sizing: border-box;
  max-width: 29.1667%;
  flex-basis: 29.1667%;
  padding: 0 7.5px;
}

.col-xs-offset-7 {
  margin-left: 29.1667%;
}

.col-xs-8 {
  box-sizing: border-box;
  max-width: 33.3333%;
  flex-basis: 33.3333%;
  padding: 0 7.5px;
}

.col-xs-offset-8 {
  margin-left: 33.3333%;
}

.col-xs-9 {
  box-sizing: border-box;
  max-width: 37.5%;
  flex-basis: 37.5%;
  padding: 0 7.5px;
}

.col-xs-offset-9 {
  margin-left: 37.5%;
}

.col-xs-10 {
  box-sizing: border-box;
  max-width: 41.6667%;
  flex-basis: 41.6667%;
  padding: 0 7.5px;
}

.col-xs-offset-10 {
  margin-left: 41.6667%;
}

.col-xs-11 {
  box-sizing: border-box;
  max-width: 45.8333%;
  flex-basis: 45.8333%;
  padding: 0 7.5px;
}

.col-xs-offset-11 {
  margin-left: 45.8333%;
}

.col-xs-12 {
  box-sizing: border-box;
  max-width: 50%;
  flex-basis: 50%;
  padding: 0 7.5px;
}

.col-xs-offset-12 {
  margin-left: 50%;
}

.col-xs-13 {
  box-sizing: border-box;
  max-width: 54.1667%;
  flex-basis: 54.1667%;
  padding: 0 7.5px;
}

.col-xs-offset-13 {
  margin-left: 54.1667%;
}

.col-xs-14 {
  box-sizing: border-box;
  max-width: 58.3333%;
  flex-basis: 58.3333%;
  padding: 0 7.5px;
}

.col-xs-offset-14 {
  margin-left: 58.3333%;
}

.col-xs-15 {
  box-sizing: border-box;
  max-width: 62.5%;
  flex-basis: 62.5%;
  padding: 0 7.5px;
}

.col-xs-offset-15 {
  margin-left: 62.5%;
}

.col-xs-16 {
  box-sizing: border-box;
  max-width: 66.6667%;
  flex-basis: 66.6667%;
  padding: 0 7.5px;
}

.col-xs-offset-16 {
  margin-left: 66.6667%;
}

.col-xs-17 {
  box-sizing: border-box;
  max-width: 70.8333%;
  flex-basis: 70.8333%;
  padding: 0 7.5px;
}

.col-xs-offset-17 {
  margin-left: 70.8333%;
}

.col-xs-18 {
  box-sizing: border-box;
  max-width: 75%;
  flex-basis: 75%;
  padding: 0 7.5px;
}

.col-xs-offset-18 {
  margin-left: 75%;
}

.col-xs-19 {
  box-sizing: border-box;
  max-width: 79.1667%;
  flex-basis: 79.1667%;
  padding: 0 7.5px;
}

.col-xs-offset-19 {
  margin-left: 79.1667%;
}

.col-xs-20 {
  box-sizing: border-box;
  max-width: 83.3333%;
  flex-basis: 83.3333%;
  padding: 0 7.5px;
}

.col-xs-offset-20 {
  margin-left: 83.3333%;
}

.col-xs-21 {
  box-sizing: border-box;
  max-width: 87.5%;
  flex-basis: 87.5%;
  padding: 0 7.5px;
}

.col-xs-offset-21 {
  margin-left: 87.5%;
}

.col-xs-22 {
  box-sizing: border-box;
  max-width: 91.6667%;
  flex-basis: 91.6667%;
  padding: 0 7.5px;
}

.col-xs-offset-22 {
  margin-left: 91.6667%;
}

.col-xs-23 {
  box-sizing: border-box;
  max-width: 95.8333%;
  flex-basis: 95.8333%;
  padding: 0 7.5px;
}

.col-xs-offset-23 {
  margin-left: 95.8333%;
}

.col-xs-24 {
  box-sizing: border-box;
  max-width: 100%;
  flex-basis: 100%;
  padding: 0 7.5px;
}

.col-xs-offset-24 {
  margin-left: 100%;
}

.row.start-xs {
  justify-content: flex-start;
}

.row.center-xs {
  justify-content: center;
}

.row.end-xs {
  justify-content: flex-end;
}

.row.top-xs {
  align-items: flex-start;
}

.row.middle-xs {
  align-items: center;
}

.row.bottom-xs {
  align-items: flex-end;
}

.row.around-xs {
  justify-content: space-around;
}

.row.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 641px) {
  .col-sm {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 7.5px;
  }

  .col-sm-1 {
    box-sizing: border-box;
    max-width: 4.16667%;
    flex-basis: 4.16667%;
    padding: 0 7.5px;
  }

  .col-sm-offset-1 {
    margin-left: 4.16667%;
  }

  .col-sm-2 {
    box-sizing: border-box;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
    padding: 0 7.5px;
  }

  .col-sm-offset-2 {
    margin-left: 8.33333%;
  }

  .col-sm-3 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0 7.5px;
  }

  .col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .col-sm-4 {
    box-sizing: border-box;
    max-width: 16.6667%;
    flex-basis: 16.6667%;
    padding: 0 7.5px;
  }

  .col-sm-offset-4 {
    margin-left: 16.6667%;
  }

  .col-sm-5 {
    box-sizing: border-box;
    max-width: 20.8333%;
    flex-basis: 20.8333%;
    padding: 0 7.5px;
  }

  .col-sm-offset-5 {
    margin-left: 20.8333%;
  }

  .col-sm-6 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0 7.5px;
  }

  .col-sm-offset-6 {
    margin-left: 25%;
  }

  .col-sm-7 {
    box-sizing: border-box;
    max-width: 29.1667%;
    flex-basis: 29.1667%;
    padding: 0 7.5px;
  }

  .col-sm-offset-7 {
    margin-left: 29.1667%;
  }

  .col-sm-8 {
    box-sizing: border-box;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 0 7.5px;
  }

  .col-sm-offset-8 {
    margin-left: 33.3333%;
  }

  .col-sm-9 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0 7.5px;
  }

  .col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .col-sm-10 {
    box-sizing: border-box;
    max-width: 41.6667%;
    flex-basis: 41.6667%;
    padding: 0 7.5px;
  }

  .col-sm-offset-10 {
    margin-left: 41.6667%;
  }

  .col-sm-11 {
    box-sizing: border-box;
    max-width: 45.8333%;
    flex-basis: 45.8333%;
    padding: 0 7.5px;
  }

  .col-sm-offset-11 {
    margin-left: 45.8333%;
  }

  .col-sm-12 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 7.5px;
  }

  .col-sm-offset-12 {
    margin-left: 50%;
  }

  .col-sm-13 {
    box-sizing: border-box;
    max-width: 54.1667%;
    flex-basis: 54.1667%;
    padding: 0 7.5px;
  }

  .col-sm-offset-13 {
    margin-left: 54.1667%;
  }

  .col-sm-14 {
    box-sizing: border-box;
    max-width: 58.3333%;
    flex-basis: 58.3333%;
    padding: 0 7.5px;
  }

  .col-sm-offset-14 {
    margin-left: 58.3333%;
  }

  .col-sm-15 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0 7.5px;
  }

  .col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .col-sm-16 {
    box-sizing: border-box;
    max-width: 66.6667%;
    flex-basis: 66.6667%;
    padding: 0 7.5px;
  }

  .col-sm-offset-16 {
    margin-left: 66.6667%;
  }

  .col-sm-17 {
    box-sizing: border-box;
    max-width: 70.8333%;
    flex-basis: 70.8333%;
    padding: 0 7.5px;
  }

  .col-sm-offset-17 {
    margin-left: 70.8333%;
  }

  .col-sm-18 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0 7.5px;
  }

  .col-sm-offset-18 {
    margin-left: 75%;
  }

  .col-sm-19 {
    box-sizing: border-box;
    max-width: 79.1667%;
    flex-basis: 79.1667%;
    padding: 0 7.5px;
  }

  .col-sm-offset-19 {
    margin-left: 79.1667%;
  }

  .col-sm-20 {
    box-sizing: border-box;
    max-width: 83.3333%;
    flex-basis: 83.3333%;
    padding: 0 7.5px;
  }

  .col-sm-offset-20 {
    margin-left: 83.3333%;
  }

  .col-sm-21 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0 7.5px;
  }

  .col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .col-sm-22 {
    box-sizing: border-box;
    max-width: 91.6667%;
    flex-basis: 91.6667%;
    padding: 0 7.5px;
  }

  .col-sm-offset-22 {
    margin-left: 91.6667%;
  }

  .col-sm-23 {
    box-sizing: border-box;
    max-width: 95.8333%;
    flex-basis: 95.8333%;
    padding: 0 7.5px;
  }

  .col-sm-offset-23 {
    margin-left: 95.8333%;
  }

  .col-sm-24 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 7.5px;
  }

  .col-sm-offset-24 {
    margin-left: 100%;
  }

  .row.start-sm {
    justify-content: flex-start;
  }

  .row.center-sm {
    justify-content: center;
  }

  .row.end-sm {
    justify-content: flex-end;
  }

  .row.top-sm {
    align-items: flex-start;
  }

  .row.middle-sm {
    align-items: center;
  }

  .row.bottom-sm {
    align-items: flex-end;
  }

  .row.around-sm {
    justify-content: space-around;
  }

  .row.between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 7.5px;
  }

  .col-md-1 {
    box-sizing: border-box;
    max-width: 4.16667%;
    flex-basis: 4.16667%;
    padding: 0 7.5px;
  }

  .col-md-offset-1 {
    margin-left: 4.16667%;
  }

  .col-md-2 {
    box-sizing: border-box;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
    padding: 0 7.5px;
  }

  .col-md-offset-2 {
    margin-left: 8.33333%;
  }

  .col-md-3 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0 7.5px;
  }

  .col-md-offset-3 {
    margin-left: 12.5%;
  }

  .col-md-4 {
    box-sizing: border-box;
    max-width: 16.6667%;
    flex-basis: 16.6667%;
    padding: 0 7.5px;
  }

  .col-md-offset-4 {
    margin-left: 16.6667%;
  }

  .col-md-5 {
    box-sizing: border-box;
    max-width: 20.8333%;
    flex-basis: 20.8333%;
    padding: 0 7.5px;
  }

  .col-md-offset-5 {
    margin-left: 20.8333%;
  }

  .col-md-6 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0 7.5px;
  }

  .col-md-offset-6 {
    margin-left: 25%;
  }

  .col-md-7 {
    box-sizing: border-box;
    max-width: 29.1667%;
    flex-basis: 29.1667%;
    padding: 0 7.5px;
  }

  .col-md-offset-7 {
    margin-left: 29.1667%;
  }

  .col-md-8 {
    box-sizing: border-box;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 0 7.5px;
  }

  .col-md-offset-8 {
    margin-left: 33.3333%;
  }

  .col-md-9 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0 7.5px;
  }

  .col-md-offset-9 {
    margin-left: 37.5%;
  }

  .col-md-10 {
    box-sizing: border-box;
    max-width: 41.6667%;
    flex-basis: 41.6667%;
    padding: 0 7.5px;
  }

  .col-md-offset-10 {
    margin-left: 41.6667%;
  }

  .col-md-11 {
    box-sizing: border-box;
    max-width: 45.8333%;
    flex-basis: 45.8333%;
    padding: 0 7.5px;
  }

  .col-md-offset-11 {
    margin-left: 45.8333%;
  }

  .col-md-12 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 7.5px;
  }

  .col-md-offset-12 {
    margin-left: 50%;
  }

  .col-md-13 {
    box-sizing: border-box;
    max-width: 54.1667%;
    flex-basis: 54.1667%;
    padding: 0 7.5px;
  }

  .col-md-offset-13 {
    margin-left: 54.1667%;
  }

  .col-md-14 {
    box-sizing: border-box;
    max-width: 58.3333%;
    flex-basis: 58.3333%;
    padding: 0 7.5px;
  }

  .col-md-offset-14 {
    margin-left: 58.3333%;
  }

  .col-md-15 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0 7.5px;
  }

  .col-md-offset-15 {
    margin-left: 62.5%;
  }

  .col-md-16 {
    box-sizing: border-box;
    max-width: 66.6667%;
    flex-basis: 66.6667%;
    padding: 0 7.5px;
  }

  .col-md-offset-16 {
    margin-left: 66.6667%;
  }

  .col-md-17 {
    box-sizing: border-box;
    max-width: 70.8333%;
    flex-basis: 70.8333%;
    padding: 0 7.5px;
  }

  .col-md-offset-17 {
    margin-left: 70.8333%;
  }

  .col-md-18 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0 7.5px;
  }

  .col-md-offset-18 {
    margin-left: 75%;
  }

  .col-md-19 {
    box-sizing: border-box;
    max-width: 79.1667%;
    flex-basis: 79.1667%;
    padding: 0 7.5px;
  }

  .col-md-offset-19 {
    margin-left: 79.1667%;
  }

  .col-md-20 {
    box-sizing: border-box;
    max-width: 83.3333%;
    flex-basis: 83.3333%;
    padding: 0 7.5px;
  }

  .col-md-offset-20 {
    margin-left: 83.3333%;
  }

  .col-md-21 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0 7.5px;
  }

  .col-md-offset-21 {
    margin-left: 87.5%;
  }

  .col-md-22 {
    box-sizing: border-box;
    max-width: 91.6667%;
    flex-basis: 91.6667%;
    padding: 0 7.5px;
  }

  .col-md-offset-22 {
    margin-left: 91.6667%;
  }

  .col-md-23 {
    box-sizing: border-box;
    max-width: 95.8333%;
    flex-basis: 95.8333%;
    padding: 0 7.5px;
  }

  .col-md-offset-23 {
    margin-left: 95.8333%;
  }

  .col-md-24 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 7.5px;
  }

  .col-md-offset-24 {
    margin-left: 100%;
  }

  .row.start-md {
    justify-content: flex-start;
  }

  .row.center-md {
    justify-content: center;
  }

  .row.end-md {
    justify-content: flex-end;
  }

  .row.top-md {
    align-items: flex-start;
  }

  .row.middle-md {
    align-items: center;
  }

  .row.bottom-md {
    align-items: flex-end;
  }

  .row.around-md {
    justify-content: space-around;
  }

  .row.between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 7.5px;
  }

  .col-lg-1 {
    box-sizing: border-box;
    max-width: 4.16667%;
    flex-basis: 4.16667%;
    padding: 0 7.5px;
  }

  .col-lg-offset-1 {
    margin-left: 4.16667%;
  }

  .col-lg-2 {
    box-sizing: border-box;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
    padding: 0 7.5px;
  }

  .col-lg-offset-2 {
    margin-left: 8.33333%;
  }

  .col-lg-3 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0 7.5px;
  }

  .col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .col-lg-4 {
    box-sizing: border-box;
    max-width: 16.6667%;
    flex-basis: 16.6667%;
    padding: 0 7.5px;
  }

  .col-lg-offset-4 {
    margin-left: 16.6667%;
  }

  .col-lg-5 {
    box-sizing: border-box;
    max-width: 20.8333%;
    flex-basis: 20.8333%;
    padding: 0 7.5px;
  }

  .col-lg-offset-5 {
    margin-left: 20.8333%;
  }

  .col-lg-6 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0 7.5px;
  }

  .col-lg-offset-6 {
    margin-left: 25%;
  }

  .col-lg-7 {
    box-sizing: border-box;
    max-width: 29.1667%;
    flex-basis: 29.1667%;
    padding: 0 7.5px;
  }

  .col-lg-offset-7 {
    margin-left: 29.1667%;
  }

  .col-lg-8 {
    box-sizing: border-box;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 0 7.5px;
  }

  .col-lg-offset-8 {
    margin-left: 33.3333%;
  }

  .col-lg-9 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0 7.5px;
  }

  .col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .col-lg-10 {
    box-sizing: border-box;
    max-width: 41.6667%;
    flex-basis: 41.6667%;
    padding: 0 7.5px;
  }

  .col-lg-offset-10 {
    margin-left: 41.6667%;
  }

  .col-lg-11 {
    box-sizing: border-box;
    max-width: 45.8333%;
    flex-basis: 45.8333%;
    padding: 0 7.5px;
  }

  .col-lg-offset-11 {
    margin-left: 45.8333%;
  }

  .col-lg-12 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 7.5px;
  }

  .col-lg-offset-12 {
    margin-left: 50%;
  }

  .col-lg-13 {
    box-sizing: border-box;
    max-width: 54.1667%;
    flex-basis: 54.1667%;
    padding: 0 7.5px;
  }

  .col-lg-offset-13 {
    margin-left: 54.1667%;
  }

  .col-lg-14 {
    box-sizing: border-box;
    max-width: 58.3333%;
    flex-basis: 58.3333%;
    padding: 0 7.5px;
  }

  .col-lg-offset-14 {
    margin-left: 58.3333%;
  }

  .col-lg-15 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0 7.5px;
  }

  .col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .col-lg-16 {
    box-sizing: border-box;
    max-width: 66.6667%;
    flex-basis: 66.6667%;
    padding: 0 7.5px;
  }

  .col-lg-offset-16 {
    margin-left: 66.6667%;
  }

  .col-lg-17 {
    box-sizing: border-box;
    max-width: 70.8333%;
    flex-basis: 70.8333%;
    padding: 0 7.5px;
  }

  .col-lg-offset-17 {
    margin-left: 70.8333%;
  }

  .col-lg-18 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0 7.5px;
  }

  .col-lg-offset-18 {
    margin-left: 75%;
  }

  .col-lg-19 {
    box-sizing: border-box;
    max-width: 79.1667%;
    flex-basis: 79.1667%;
    padding: 0 7.5px;
  }

  .col-lg-offset-19 {
    margin-left: 79.1667%;
  }

  .col-lg-20 {
    box-sizing: border-box;
    max-width: 83.3333%;
    flex-basis: 83.3333%;
    padding: 0 7.5px;
  }

  .col-lg-offset-20 {
    margin-left: 83.3333%;
  }

  .col-lg-21 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0 7.5px;
  }

  .col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .col-lg-22 {
    box-sizing: border-box;
    max-width: 91.6667%;
    flex-basis: 91.6667%;
    padding: 0 7.5px;
  }

  .col-lg-offset-22 {
    margin-left: 91.6667%;
  }

  .col-lg-23 {
    box-sizing: border-box;
    max-width: 95.8333%;
    flex-basis: 95.8333%;
    padding: 0 7.5px;
  }

  .col-lg-offset-23 {
    margin-left: 95.8333%;
  }

  .col-lg-24 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 7.5px;
  }

  .col-lg-offset-24 {
    margin-left: 100%;
  }

  .row.start-lg {
    justify-content: flex-start;
  }

  .row.center-lg {
    justify-content: center;
  }

  .row.end-lg {
    justify-content: flex-end;
  }

  .row.top-lg {
    align-items: flex-start;
  }

  .row.middle-lg {
    align-items: center;
  }

  .row.bottom-lg {
    align-items: flex-end;
  }

  .row.around-lg {
    justify-content: space-around;
  }

  .row.between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 7.5px;
  }

  .col-xl-1 {
    box-sizing: border-box;
    max-width: 4.16667%;
    flex-basis: 4.16667%;
    padding: 0 7.5px;
  }

  .col-xl-offset-1 {
    margin-left: 4.16667%;
  }

  .col-xl-2 {
    box-sizing: border-box;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
    padding: 0 7.5px;
  }

  .col-xl-offset-2 {
    margin-left: 8.33333%;
  }

  .col-xl-3 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0 7.5px;
  }

  .col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .col-xl-4 {
    box-sizing: border-box;
    max-width: 16.6667%;
    flex-basis: 16.6667%;
    padding: 0 7.5px;
  }

  .col-xl-offset-4 {
    margin-left: 16.6667%;
  }

  .col-xl-5 {
    box-sizing: border-box;
    max-width: 20.8333%;
    flex-basis: 20.8333%;
    padding: 0 7.5px;
  }

  .col-xl-offset-5 {
    margin-left: 20.8333%;
  }

  .col-xl-6 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0 7.5px;
  }

  .col-xl-offset-6 {
    margin-left: 25%;
  }

  .col-xl-7 {
    box-sizing: border-box;
    max-width: 29.1667%;
    flex-basis: 29.1667%;
    padding: 0 7.5px;
  }

  .col-xl-offset-7 {
    margin-left: 29.1667%;
  }

  .col-xl-8 {
    box-sizing: border-box;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 0 7.5px;
  }

  .col-xl-offset-8 {
    margin-left: 33.3333%;
  }

  .col-xl-9 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0 7.5px;
  }

  .col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .col-xl-10 {
    box-sizing: border-box;
    max-width: 41.6667%;
    flex-basis: 41.6667%;
    padding: 0 7.5px;
  }

  .col-xl-offset-10 {
    margin-left: 41.6667%;
  }

  .col-xl-11 {
    box-sizing: border-box;
    max-width: 45.8333%;
    flex-basis: 45.8333%;
    padding: 0 7.5px;
  }

  .col-xl-offset-11 {
    margin-left: 45.8333%;
  }

  .col-xl-12 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 7.5px;
  }

  .col-xl-offset-12 {
    margin-left: 50%;
  }

  .col-xl-13 {
    box-sizing: border-box;
    max-width: 54.1667%;
    flex-basis: 54.1667%;
    padding: 0 7.5px;
  }

  .col-xl-offset-13 {
    margin-left: 54.1667%;
  }

  .col-xl-14 {
    box-sizing: border-box;
    max-width: 58.3333%;
    flex-basis: 58.3333%;
    padding: 0 7.5px;
  }

  .col-xl-offset-14 {
    margin-left: 58.3333%;
  }

  .col-xl-15 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0 7.5px;
  }

  .col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .col-xl-16 {
    box-sizing: border-box;
    max-width: 66.6667%;
    flex-basis: 66.6667%;
    padding: 0 7.5px;
  }

  .col-xl-offset-16 {
    margin-left: 66.6667%;
  }

  .col-xl-17 {
    box-sizing: border-box;
    max-width: 70.8333%;
    flex-basis: 70.8333%;
    padding: 0 7.5px;
  }

  .col-xl-offset-17 {
    margin-left: 70.8333%;
  }

  .col-xl-18 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0 7.5px;
  }

  .col-xl-offset-18 {
    margin-left: 75%;
  }

  .col-xl-19 {
    box-sizing: border-box;
    max-width: 79.1667%;
    flex-basis: 79.1667%;
    padding: 0 7.5px;
  }

  .col-xl-offset-19 {
    margin-left: 79.1667%;
  }

  .col-xl-20 {
    box-sizing: border-box;
    max-width: 83.3333%;
    flex-basis: 83.3333%;
    padding: 0 7.5px;
  }

  .col-xl-offset-20 {
    margin-left: 83.3333%;
  }

  .col-xl-21 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0 7.5px;
  }

  .col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .col-xl-22 {
    box-sizing: border-box;
    max-width: 91.6667%;
    flex-basis: 91.6667%;
    padding: 0 7.5px;
  }

  .col-xl-offset-22 {
    margin-left: 91.6667%;
  }

  .col-xl-23 {
    box-sizing: border-box;
    max-width: 95.8333%;
    flex-basis: 95.8333%;
    padding: 0 7.5px;
  }

  .col-xl-offset-23 {
    margin-left: 95.8333%;
  }

  .col-xl-24 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 7.5px;
  }

  .col-xl-offset-24 {
    margin-left: 100%;
  }

  .row.start-xl {
    justify-content: flex-start;
  }

  .row.center-xl {
    justify-content: center;
  }

  .row.end-xl {
    justify-content: flex-end;
  }

  .row.top-xl {
    align-items: flex-start;
  }

  .row.middle-xl {
    align-items: center;
  }

  .row.bottom-xl {
    align-items: flex-end;
  }

  .row.around-xl {
    justify-content: space-around;
  }

  .row.between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }
}

.col-gutter-lr {
  padding: 0 0 7.5px;
}

.col-no-gutter {
  padding: 0;
}

.show {
  display: block !important;
}

.row.show {
  display: flex !important;
}

.hide {
  display: none !important;
}

.show-xs {
  display: block !important;
}

.row.show-xs {
  display: flex !important;
}

.hide-xs {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .show-xs-only {
    display: block !important;
  }

  .row.show-xs-only {
    display: flex !important;
  }

  .hide-xs-only {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) {
  .show-sm {
    display: block !important;
  }

  .row.show-sm {
    display: flex !important;
  }

  .hide-sm {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1023px) {
  .show-sm-only {
    display: block !important;
  }

  .row.show-sm-only {
    display: flex !important;
  }

  .hide-sm-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .show-md {
    display: block !important;
  }

  .row.show-md {
    display: flex !important;
  }

  .hide-md {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1399px) {
  .show-md-only {
    display: block !important;
  }

  .row.show-md-only {
    display: flex !important;
  }

  .hide-md-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1400px) {
  .show-lg {
    display: block !important;
  }

  .row.show-lg {
    display: flex !important;
  }

  .hide-lg {
    display: none !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1790px) {
  .show-lg-only {
    display: block !important;
  }

  .row.show-lg-only {
    display: flex !important;
  }

  .hide-lg-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1791px) {
  .show-xl {
    display: block !important;
  }

  .row.show-xl {
    display: flex !important;
  }

  .hide-xl {
    display: none !important;
  }
}

.col-xs {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs {
    padding: 0 7.5px;
  }
}

.col-xs-1 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-1 {
    padding: 0 7.5px;
  }
}

.col-xs-2 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-2 {
    padding: 0 7.5px;
  }
}

.col-xs-3 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-3 {
    padding: 0 7.5px;
  }
}

.col-xs-4 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-4 {
    padding: 0 7.5px;
  }
}

.col-xs-5 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-5 {
    padding: 0 7.5px;
  }
}

.col-xs-6 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-6 {
    padding: 0 7.5px;
  }
}

.col-xs-7 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-7 {
    padding: 0 7.5px;
  }
}

.col-xs-8 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-8 {
    padding: 0 7.5px;
  }
}

.col-xs-9 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-9 {
    padding: 0 7.5px;
  }
}

.col-xs-10 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-10 {
    padding: 0 7.5px;
  }
}

.col-xs-11 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-11 {
    padding: 0 7.5px;
  }
}

.col-xs-12 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-12 {
    padding: 0 7.5px;
  }
}

.col-xs-13 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-13 {
    padding: 0 7.5px;
  }
}

.col-xs-14 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-14 {
    padding: 0 7.5px;
  }
}

.col-xs-15 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-15 {
    padding: 0 7.5px;
  }
}

.col-xs-16 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-16 {
    padding: 0 7.5px;
  }
}

.col-xs-17 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-17 {
    padding: 0 7.5px;
  }
}

.col-xs-18 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-18 {
    padding: 0 7.5px;
  }
}

.col-xs-19 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-19 {
    padding: 0 7.5px;
  }
}

.col-xs-20 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-20 {
    padding: 0 7.5px;
  }
}

.col-xs-21 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-21 {
    padding: 0 7.5px;
  }
}

.col-xs-22 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-22 {
    padding: 0 7.5px;
  }
}

.col-xs-23 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-23 {
    padding: 0 7.5px;
  }
}

.col-xs-24 {
  padding: 0 7.5px;
}

@media only screen and (min-width: 641px) {
  .col-xs-24, .col-sm {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-1 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-1 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-2 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-2 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-3 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-3 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-4 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-4 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-5 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-5 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-6 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-6 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-7 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-7 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-8 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-8 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-9 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-9 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-10 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-10 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-11 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-11 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-12 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-12 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-13 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-13 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-14 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-14 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-15 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-15 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-16 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-16 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-17 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-17 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-18 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-18 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-19 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-19 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-20 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-20 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-21 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-21 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-22 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-22 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-23 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-23 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-24 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-24 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-1 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-1 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-2 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-2 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-3 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-3 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-4 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-4 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-5 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-5 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-6 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-6 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-7 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-7 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-8 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-8 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-9 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-9 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-10 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-10 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-11 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-11 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-12 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-12 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-13 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-13 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-14 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-14 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-15 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-15 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-16 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-16 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-17 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-17 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-18 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-18 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-19 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-19 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-20 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-20 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-21 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-21 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-22 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-22 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-23 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-23 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-24 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-24 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-1 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-1 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-2 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-2 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-3 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-3 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-4 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-4 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-5 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-5 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-6 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-6 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-7 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-7 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-8 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-8 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-9 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-9 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-10 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-10 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-11 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-11 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-12 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-12 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-13 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-13 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-14 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-14 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-15 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-15 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-16 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-16 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-17 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-17 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-18 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-18 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-19 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-19 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-20 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-20 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-21 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-21 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-22 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-22 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-23 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-23 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-lg-24 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1400px) and (min-width: 641px) {
  .col-lg-24 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-1 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-1 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-2 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-2 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-3 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-3 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-4 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-4 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-5 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-5 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-6 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-6 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-7 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-7 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-8 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-8 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-9 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-9 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-10 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-10 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-11 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-11 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-12 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-12 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-13 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-13 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-14 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-14 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-15 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-15 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-16 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-16 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-17 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-17 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-18 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-18 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-19 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-19 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-20 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-20 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-21 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-21 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-22 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-22 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-23 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-23 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-24 {
    padding: 0 7.5px;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-24 {
    padding: 0 7.5px;
  }
}

body {
  color: #000;
  background-color: #f8f8f8;
  font-family: Sligoil Micro;
  font-size: max(9.5px, min(3.3vw, 13.5px));
  line-height: 1.3;
}

@media only screen and (min-width: 641px) {
  body {
    font-size: max(11.5px, min(1.8vw, 15px));
  }
}

@media only screen and (min-width: 1024px) {
  body {
    font-size: max(13.5px, min(.9vw, 15px));
    line-height: 1.15;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-underline-offset: .15em;
  text-decoration: underline;
  text-decoration-thickness: .5px;
}

::selection {
  color: #f8f8f8;
  background-color: #000;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: normal;
  font-size: inherit;
  margin: 0;
  padding: 0;
}

img {
  max-inline-size: 100%;
}

.column-test {
  min-height: 100vh;
  background-color: #ff00001a;
}

@media only screen and (min-width: 1024px) {
  .col-md-offset-0 {
    margin-left: 0;
  }
}

.text-right {
  text-align: right;
}

.header {
  z-index: 2;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.header:after, .header:before {
  content: "";
  width: .5px;
  z-index: 2;
  background-color: #000;
  animation: reveal 1s ease-in forwards;
  position: absolute;
  top: 0;
  bottom: 100%;
}

@media only screen and (min-width: 1024px) {
  .header:after, .header:before {
    display: none;
  }
}

.header:before {
  left: 12.5%;
}

@media only screen and (min-width: 641px) {
  .header:before {
    left: 8.33333%;
  }
}

.header:after {
  display: none;
  right: 8.33333%;
}

@media only screen and (min-width: 641px) {
  .header:after {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .header:after {
    display: none;
  }
}

.header__bg {
  top: -40px;
  bottom: -40px;
  left: -40px;
  right: -40px;
  bottom: initial;
  height: calc(40px + 2.5rem);
  z-index: 0;
  filter: blur(10px);
  background: linear-gradient(#fbfbfb 0% 60%, #fbfbfb00 100%);
  position: absolute;
}

@media only screen and (min-width: 1024px) {
  .header__bg {
    height: calc(40px + 2.5rem);
  }
}

.header a, .header p {
  pointer-events: all;
}

.header__column {
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .header__column {
    height: 100vh;
  }

  .header__column:after {
    content: "";
    width: .5px;
    background-color: #000;
    animation: reveal 1s ease-in .25s forwards;
    position: absolute;
    top: 0;
    bottom: 100%;
    right: 0;
  }
}

.header__column:nth-child(2):after {
  animation-delay: .5s;
}

.header__column:nth-child(3):after {
  animation-delay: .75s;
}

.header__content {
  margin-top: 7.5px;
}

@media only screen and (min-width: 1024px) {
  .header__content {
    margin-top: 7.5px;
  }
}

@keyframes reveal {
  0% {
    bottom: 100%;
  }

  100% {
    bottom: 0%;
  }
}

.page-container {
  position: relative;
  overflow-x: clip;
}

@media only screen and (min-width: 1024px) {
  [data-about-text] {
    pointer-events: none;
  }

  [data-about-text].about__content--ready {
    pointer-events: all;
  }
}

[data-about-text] span {
  will-change: transform;
  transform-origin: center;
  opacity: .03;
  transition: transform 1s ease-out, opacity 1s ease-in;
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  [data-about-text] span {
    transition: transform 1s ease-in, opacity 1s ease-in;
  }

  [data-about-text] a:hover {
    color: #f8f8f8;
    text-shadow: -.5px -.5px #000, .5px -.5px #000, -.5px .5px #000, .5px .5px #000;
  }
}

.about {
  z-index: 2;
  margin-top: calc(1.5rem + 22.5px);
  margin-bottom: calc(5rem + 75px);
  position: relative;
}

@media only screen and (min-width: 641px) {
  .about {
    margin-top: calc(6rem + 90px);
    margin-bottom: calc(6rem + 90px);
  }
}

@media only screen and (min-width: 1024px) {
  .about {
    margin-top: calc(5rem + 75px);
    margin-bottom: calc(5rem + 75px);
  }
}

.about p {
  line-height: 1.3;
}

@media only screen and (min-width: 641px) {
  .about p {
    font-size: 2.6vw;
  }
}

@media only screen and (min-width: 1024px) {
  .about p {
    letter-spacing: -.025em;
    font-size: max(16px, min(3.1vw, 50px));
  }
}

.about p + .about p {
  padding-top: 1em;
}

.about p + p {
  margin-top: 1em;
}

.about__content, .projects-container, .projects {
  position: relative;
}

.projects__hovered-proj {
  width: 1.5px;
  height: calc(1rem + 15px);
  opacity: 0;
  background-color: #000;
  margin-left: -9px;
  transition: opacity .15s;
  position: fixed;
  top: calc(-1rem - 15px);
}

.projects:hover .projects__hovered-proj {
  height: calc(1rem + 15px);
  opacity: 1;
  margin-left: -9px;
  top: calc(-1rem - 15px);
}

.project-bg {
  z-index: -1;
  filter: blur(40px) saturate(4);
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.project-bg video, .project-bg img {
  width: 110%;
  height: 110%;
  object-fit: cover;
  object-position: top left;
  opacity: 0;
  z-index: 1;
  visibility: hidden;
  transition: opacity .5s, visibility 0s .5s;
  position: absolute;
  top: -5%;
  bottom: -5%;
  left: -5%;
  right: -5%;
}

.project-bg:before {
  content: "";
  top: -40px;
  bottom: -40px;
  left: -40px;
  right: -40px;
  bottom: initial;
  height: 30%;
  z-index: 2;
  --mask: radial-gradient(107.35px at 50% calc(100% - 145px), #fbfbfb 99%, #fbfbfb00 101%) calc(50% - 100px) 0 / 200px 100%, radial-gradient(107.35px at 50% calc(100% + 95px), #fbfbfb00 99%, #fbfbfb 101%) 50% calc(100% - 50px) / 200px 100% repeat-x;
  background: var(--mask);
  position: absolute;
}

.project-bg .project-bg__video--active {
  visibility: visible;
  opacity: .2;
  transition: opacity .3s, visibility;
}

.project {
  will-change: height;
  z-index: 1;
  cursor: pointer;
  max-height: calc(2rem + 30px + 1.4em);
  opacity: 0;
  transition: max-height 1.25s;
  animation: fadeIn .5s ease-in 1s forwards;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 641px) {
  .project {
    max-height: calc(2rem + 30px);
  }
}

@media only screen and (min-width: 1024px) {
  .project {
    max-height: calc(1rem + 15px);
    transition: max-height .75s;
  }
}

.project:not(:last-child) {
  margin-bottom: 7.5px;
}

@media only screen and (min-width: 1024px) {
  .project:not(:last-child) {
    margin-bottom: 0;
  }

  .project * {
    line-height: 1;
  }
}

.project a {
  pointer-events: none;
}

.project:nth-child(2) {
  margin-top: calc(-1rem - 22.5px);
}

@media only screen and (min-width: 1024px) {
  .project:nth-child(2) {
    margin-top: calc(-1rem - 15px);
  }
}

.project--active {
  max-height: 200vw !important;
}

@media only screen and (min-width: 1024px) {
  .project--active {
    max-height: 41.6667vw !important;
  }
}

.project--active a {
  pointer-events: all;
  text-underline-offset: 2px;
  -webkit-text-decoration: underline #00000080;
  text-decoration: underline #00000080;
  text-decoration-thickness: .5px;
}

.project--active:after {
  opacity: 1;
  right: 0%;
}

.project.project--heading {
  height: calc(1rem + 22.5px);
  pointer-events: none;
  z-index: 3;
  margin-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: calc(1rem + 22.5px);
}

@media only screen and (max-width: 640px) {
  .project.project--heading {
    top: calc(1rem + 22.5px) !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1023px) {
  .project.project--heading {
    top: calc(1rem + 22.5px) !important;
  }
}

@media only screen and (min-width: 1024px) {
  .project.project--heading {
    height: calc(1rem + 15px);
    top: calc(1rem + 15px);
  }
}

.project.project--heading a {
  pointer-events: all;
}

.project.project--year {
  height: calc(1rem + 22.5px);
  margin-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: calc(1rem + 22.5px);
}

@media only screen and (min-width: 1024px) {
  .project.project--year {
    height: calc(1rem + 15px);
    top: calc(1rem + 15px);
  }
}

.projects__group:nth-child(2) .project.project--year {
  margin-top: calc(-1rem - 22.5px);
}

@media only screen and (min-width: 1024px) {
  .projects__group:nth-child(2) .project.project--year {
    margin-top: calc(-1rem - 15px);
  }
}

.project--active .project__content--title a:first-child:after {
  content: "";
  width: .75em;
  height: .75em;
  background-image: url("arrow.9f92a5d9.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: -.1em;
  margin-left: .4em;
  display: inline-block;
}

@media only screen and (min-width: 1024px) {
  .project__content--title > * {
    width: 82%;
  }
}

.project__content {
  flex-direction: column;
  display: flex;
}

.project__content p + p {
  margin-top: calc(.5rem + 7.5px);
}

@media only screen and (min-width: 1024px) {
  .project__content p + p {
    margin-top: calc(1rem + 15px);
  }
}

.project__content--align-right {
  text-align: right;
}

.project__column {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .project__column {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }

  .project__column--1:before {
    content: "";
    width: 1.5px;
    height: 0%;
    background-color: #000;
    transition: height .75s;
    position: absolute;
    top: 0;
    left: -1.5px;
  }

  .project--active .project__column--1:before {
    height: 100%;
  }
}

.project__column-img {
  max-height: 200vw;
  padding: 0;
  display: none;
  position: relative;
  overflow-y: hidden;
}

@media only screen and (min-width: 1024px) {
  .project__column-img {
    max-height: 41.6667vw;
  }
}

.in-viewport .project__column-img {
  display: block;
}

.project__column-img .project__content {
  height: 100%;
  filter: blur(15px) saturate(1.05);
  transition: filter .25s ease-in;
  display: block;
  position: relative;
}

.project--active.project--video-loaded .project__column-img .project__content {
  filter: blur() saturate() !important;
}

.project__column-img img {
  z-index: 2;
  max-inline-size: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 1;
  background-color: #d3d3d3;
  transition: opacity 0s .75s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.project--active.project--video-loaded .project__column-img img {
  opacity: 0;
  transition: opacity;
}

.project__column-img video {
  max-inline-size: 100%;
  height: auto;
  visibility: hidden;
  transition: visibility 0s 1s;
  position: relative;
}

.project--active .project__column-img video {
  visibility: visible;
  transition: visibility;
}

.project__description {
  max-width: 95%;
}

@media only screen and (min-width: 1024px) {
  .project__description {
    line-height: 1.3;
  }
}

.project__description:after {
  content: "＊";
  text-align: center;
  margin-top: 2.1em;
  margin-bottom: 4.2em;
  display: block;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .project__description:after {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*# sourceMappingURL=index.b8aa9bdb.css.map */
