.header {
  position: fixed;
  inset: 0;
  z-index: 2;
  pointer-events: none;
  
  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 0.5px;
    background-color: black;
    top: 0;
    bottom: 100%;
    z-index: 2;
    animation: reveal 1s ease-in forwards;
    
    @media #{$breakpoint-md-up} {
      display: none;
    }
  }
  &:before {
    left: calc(100% / #{$grid-columns} * 3);
    @media #{$breakpoint-sm-up} {
      left: calc(100% / #{$grid-columns} * 2);
    }
  }
  &:after {
    display: none;
    right: calc(100% / #{$grid-columns} * 2);

    @media #{$breakpoint-sm-up} {
      display: block;
    }
    @media #{$breakpoint-md-up} {
      display: none;
    }
  }

  &__bg {
    position: absolute;
    inset: -40px;
    bottom: initial;
    height: calc(40px + 2.5rem);
    z-index: 0;
    background: linear-gradient(180deg, rgba(251,251,251, 1) 0%, rgba(251,251,251, 1) 60%, rgba(251,251,251, 0) 100%);
    filter: blur(10px);

    @media #{$breakpoint-md-up} {
      height: calc(40px + 2.5rem);
    }
  }

  a, p {
    pointer-events: all;
  }

  &__column {
    position: relative;

    @media #{$breakpoint-md-up} {
      height: 100vh;

      &:after {
        content: '';
        position: absolute;
        width: 0.5px;
        background-color: black;
        top: 0;
        bottom: 100%;
        right: 0;
        animation: reveal 1s ease-in forwards 0.25s;
      }
    }

    &:nth-child(2):after {
      animation-delay: 0.5s;
    }
    &:nth-child(3):after {
      animation-delay: 0.75s;
    }
  }

  &__content {
    margin-top: $gutter-size--mobile;

    @media #{$breakpoint-md-up} {
      margin-top: $gutter-size;
    }
  }
}


@keyframes reveal {
  0% {
    bottom: 100%;
  }
  100% {
    bottom: 0%;
  }
}