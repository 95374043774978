// Mixin to run inside of for loop - creates col/breakpoint classes * Not for developer use *
@mixin col-factory-MA-pad-hack($thisPrefix) {
  .col-#{$thisPrefix} {
    //MA HACK
    @if $thisPrefix == 'xs' {
      padding: $gutter--mobile;
    } @else if $thisPrefix == "sm" {
      padding: $gutter;
    } @else {
      padding: $gutter;
    }

    @media #{$breakpoint-sm-up} {
      padding: $gutter;
    }
  }

  @for $i from 1 through $grid-columns {
    .col-#{$thisPrefix}-#{$i} {
      //MA HACK
      @if $thisPrefix == 'xs' {
        padding: $gutter--mobile;
      } @else if $thisPrefix == "sm" {
        padding: $gutter;
      } @else {
        padding: $gutter;
      }

      @media #{$breakpoint-sm-up} {
        padding: $gutter;
      }
    }
  }
} // Mixin to run inside of for loop - creates col/breakpoint classes

@for $i from 1 through length($breakpoint-up-prefixes) {
  $thisPrefix: nth($breakpoint-up-prefixes, $i);
  @if $thisPrefix == "xs" {
    @include col-factory-MA-pad-hack($thisPrefix);
  } @else if $thisPrefix == "sm" {
    @media #{$breakpoint-sm-up} {
      @include col-factory-MA-pad-hack($thisPrefix);
    }
  } @else if $thisPrefix == "md" {
    @media #{$breakpoint-md-up} {
      @include col-factory-MA-pad-hack($thisPrefix);
    }
  } @else if $thisPrefix == "lg" {
    @media #{$breakpoint-lg-up} {
      @include col-factory-MA-pad-hack($thisPrefix);
    }
  } @else if $thisPrefix == "xl" {
    @media #{$breakpoint-xl-up} {
      @include col-factory-MA-pad-hack($thisPrefix);
    }
  }
}
