body {
  font-family: "Sligoil Micro";
  font-size: clamp(9.5px, 3.3vw, 13.5px);
  line-height: 1.3;
  background-color: $color-light-grey;
  color: black;

  @media #{$breakpoint-sm-up} {
    font-size: clamp(11.5px, 1.8vw, 15px);
  }

  @media #{$breakpoint-md-up} {
    font-size: clamp(13.5px, 0.9vw, 15px);
    line-height: 1.15;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 0.5px;
  }
}

::selection {
  color: $color-light-grey;
  background-color: black;
}


h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: inherit;
}

img {
  max-inline-size: 100%;
}
